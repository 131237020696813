import { CenterScrollPanel } from '@components/basic-components/center-scroll-panel';
import { ProfileLink } from '@components/profile/profile-link';
import { ViewsType } from '@typesSrc/views';
import { useConsent } from '../context/consent-context';
import { useNavigate } from 'react-router';
import BaseView from '@views/base-view';
import React from 'react';
export default function PrivacyPolicyView() {
    const navigate = useNavigate();
    const { consent, setConsent } = useConsent();
    return (React.createElement(React.Fragment, null,
        React.createElement(BaseView, { view: ViewsType.PRIVACY_POLICY, header: {
                showBack: true,
                onBack: () => navigate(-1),
                title: 'MIKA-Game - Internet-Datenschutzerklärung',
            }, showFooter: false },
            React.createElement(CenterScrollPanel, { className: "flex-1 text-darkBlue standalone-page-style show-bullet-points" },
                React.createElement("h1", { className: "!mt-0" }, "MIKA-Game\u00AE - Internet-Datenschutzerkl\u00E4rung"),
                React.createElement("p", null, "Diese Datenschutzerkl\u00E4rung bezieht sich auf das MIKA-Game\u00AE in Herausgeberschaft des BIBB."),
                React.createElement("h2", null, "1. Datenschutz und Datenverarbeitung"),
                React.createElement("p", null, "Der Schutz Ihrer Privatsph\u00E4re bei der Verarbeitung personenbezogener Daten ist f\u00FCr uns sehr wichtig. Wir verarbeiten personenbezogene Daten, die beim Besuch unserer Dienste erhoben werden, gem\u00E4\u00DF den gesetzlichen Bestimmungen. Unsere Website kann Links zu Internetseiten anderer Anbieter enthalten, auf die sich diese Datenschutzerkl\u00E4rung nicht erstreckt."),
                React.createElement("h3", null, "Verantwortliche und Datenschutzbeauftragte"),
                React.createElement("p", null,
                    "Verantwortlich f\u00FCr die Datenverarbeitung ist das Bundesinstitut f\u00FCr Berufsbildung, Friedrich-Ebert-Allee 114 \u2013 116 in 53113 Bonn. Kontakt: E-Mail an",
                    ' ',
                    React.createElement("a", { href: "mailto:mika@bibb.de" }, "mika@bibb.de"),
                    React.createElement("br", null),
                    "F\u00FCr Fragen zum Thema Datenschutz steht Ihnen der/die Datenschutzbeauftragte des Bundesinstituts f\u00FCr Berufsbildung per E-Mail (",
                    React.createElement("a", { href: "mailto:datenschutz@bibb.de" }, "datenschutz@bibb.de"),
                    ") oder telefonisch unter Tel.",
                    ' ',
                    React.createElement("a", { href: "tel:+4902281072237" }, "0228 107-2237"),
                    " zur Verf\u00FCgung."),
                React.createElement("h3", null, "Was sind personenbezogene Daten?"),
                React.createElement("p", null, "Daten sind gem\u00E4\u00DF Datenschutzgrundverordnung personenbezogen, wenn sie entweder einer identifizierten nat\u00FCrlichen Person zugeordnet sind oder einer nat\u00FCrlichen Person zugeordnet werden k\u00F6nnen, so dass diese Person durch die Daten identifizierbar ist. Hierunter fallen alle Angaben, die R\u00FCckschl\u00FCsse auf ihre Identit\u00E4t erm\u00F6glichen (z. B. Ihr Foto oder Ihr Realname). N\u00E4here Informationen zu personenbezogenen Daten finden Sie in Art. 4 Abs. 1 DSGVO."),
                React.createElement("h2", null, "2. Verwendung von MIKA-Game\u00AE ohne Anmeldung/Registrierung"),
                React.createElement("p", null, "Wenn Sie das MIKA-Game\u00AE ohne Registrierung eines Kontos bzw. ohne Anmeldung mit Ihrem Konto verwenden, wird automatisch ein Gastprofil erzeugt."),
                React.createElement("p", null, "Ihr Gastprofil enth\u00E4lt einen Nickname sowie den MIKA-Avatar als Profilbild. Ihr Nickname setzt sich aus dem Wort Gast und einer haupts\u00E4chlich zuf\u00E4llig erzeugten Zahlenkombination zusammen. Sie k\u00F6nnen Ihren Nickname unter \u201EProfil\u201C anpassen. Durch Klick auf Ihren Nickname wird Ihr Gastprofil (Nickname und MIKA-Avatar, wenn von Ihnen erg\u00E4nzt Berufsbezeichnung und Status) f\u00FCr andere Nutzende sichtbar."),
                React.createElement("p", null, "Die Verwendung ohne Registrierung mittels Gastprofil erm\u00F6glicht es Ihnen mit anderen Nutzenden zu spielen. Die anderen Nutzenden sehen lediglich den automatisch erzeugten Nickname, den MIKA-Avatar und die von Ihnen ggf. erg\u00E4nzten Angaben."),
                React.createElement("p", null),
                React.createElement("ul", null,
                    React.createElement("li", null, "Sie k\u00F6nnen mit Ihrem Gastprofil an 1vs1-Spielen teilnehmen. Andere Nutzende k\u00F6nnen Sie \u00FCber die Suchfunktion finden und dazu herausfordern. Nach Spielende ist ersichtlich wer mit welcher Punktzahl das Quiz gewonnen hat."),
                    React.createElement("li", null, "Sie k\u00F6nnen an einem Klassenzimmerquiz teilnehmen oder andere dazu einladen. Ihr Gastprofil wird den anderen Mitgliedern des Klassenzimmers angezeigt. Nach Quizende wird der Punktestand jedes Mitglieds des Klassenzimmers in einem Ranking angezeigt."),
                    React.createElement("li", null, "Quizhistorie: Sind Sie die h\u00E4ufigste Gegnerin/der h\u00E4ufigste Gegner eines anderen Accounts so wird in dessen Quizhistorie Ihr Gastprofil unter Lieblingsgegner/-in angezeigt.")),
                React.createElement("p", null, "Unter Profil k\u00F6nnen Sie:"),
                React.createElement("ul", null,
                    React.createElement("li", null, "Ihr Gastprofil in einen MIKA-Lernwelt Account \u00FCbertragen"),
                    React.createElement("li", null, "Ihr Gastprofil und damit zusammenh\u00E4ngende Spieldaten l\u00F6schen.")),
                React.createElement("p", null,
                    "Das Gastprofil bleibt erhalten bis es explizit gel\u00F6scht wird. Bis dahin wird der Zugangsschl\u00FCssel lokal in Ihrem Browser gespeichert. Ein Zugangsschl\u00FCssel, auch Access Token genannt, ist eine Art digitaler Schl\u00FCssel, der Ihrem Browser erm\u00F6glicht, auf ein bestimmtes Konto oder eine Sitzung zuzugreifen, ohne dass Sie Ihre Anmeldeinformationen st\u00E4ndig neu eingeben m\u00FCssen. Er enth\u00E4lt Informationen, die den Browser und das Konto verkn\u00FCpfen, und wird verwendet, um Ihre Identit\u00E4t zu best\u00E4tigen und sicherzustellen, dass nur Sie auf Ihre Daten zugreifen k\u00F6nnen. Es funktioniert also \u00E4hnlich wie ein gestempeltes Ticket: Solange der Zugangsschl\u00FCssel g\u00FCltig ist, k\u00F6nnen Sie auf die gesch\u00FCtzten Bereiche zugreifen, ohne sich jedes Mal erneut anmelden zu m\u00FCssen. Der Zugangsschl\u00FCssel wird lokal (d.h. auf Ihrem Ger\u00E4t) gespeichert. Nach Schlie\u00DFen und wieder \u00D6ffnen des Browsers k\u00F6nnen Sie daher mit Ihrem Gastprofil weiterspielen und Ihre Quizhistorie einsehen. Das Gastprofil ist ger\u00E4tegebunden und browserspezifisch.",
                    React.createElement("br", null),
                    "Wenn Sie Ihr Gastprofil l\u00F6schen werden ebenfalls Ihre Spieldaten unwiderruflich gel\u00F6scht. Ihr Gastprofil verschwindet somit auch vollst\u00E4ndig aus den oben genannten Punkten."),
                React.createElement("h3", null, "Push-Nachrichten"),
                React.createElement("p", null, "Wir verwenden Push-Nachrichten, um Ihnen ein reibungsloses Spielerlebnis zu erm\u00F6glichen. Daf\u00FCr nutzen wir den Dienst Google Firebase. Google Firebase ist ein Dienst von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, deren Konzernmutter in den USA sitzt. Es handelt sich um eine Entwicklungspattform, die verschiedene Dienste f\u00FCr Webanwendung und Appanwendungen bereitstellt."),
                React.createElement("p", null, "Wir nutzen folgenden Google Firebase Dienst f\u00FCr die Push-Nachrichten: Google Cloud Messaging."),
                React.createElement("p", null,
                    "Sofern Sie dem Empfang von Push-Nachrichten zugestimmt habe, wird Ihre IP-Adresse an Server der Firmen Google und Apple gesendet. N\u00E4here Informationen zu deren Datenschutzeinstellungen finden Sie hier:",
                    ' ',
                    React.createElement("a", { href: "https://firebase.google.com/terms/data-processing-terms", target: "_blank" }, "https://firebase.google.com/terms/data-processing-terms"),
                    ' ',
                    "und hier",
                    ' ',
                    React.createElement("a", { href: "https://www.apple.com/de/legal/privacy/de-ww/", target: "_blank" }, "https://www.apple.com/de/legal/privacy/de-ww/")),
                React.createElement("p", null, "Nutzen Sie die MIKA-Game App auf einem Android Endger\u00E4t, erhalten Sie die Push-Benachrichtigungen ausschlie\u00DFlich \u00FCber den bei Android integrierten Push-Dienst \u201EFirebase Cloud Messaging\u201C. Wenn Sie die App auf einem iOS-Ger\u00E4t nutzen, erfolgt eine Weiterleitung an die Server der Firma Apple, ebenfalls mit Sitz in den USA, die Ihnen Push-Nachrichten \u00FCber den Dienst \u201EApple Push Notifications\u201C zusendet."),
                React.createElement("p", null, "Sofern Sie MIKA-Game \u00FCber einen Webbrowser nutzen, und dem Empfang von Push-Nachrichten (\u201EWeb Push\u201C) zugestimmt haben, nutzen wir den Google Firebase Dienst f\u00FCr den Versand von Push-Nachrichten. In Abh\u00E4ngigkeit davon, welchen Webbrowser Sie verwenden, kann der Webbrowser einen dritten Push-Dienst f\u00FCr die Zustellung der Push-Nachrichten nutzen. Die Auswahl dieses Diensts obliegt dem Webbrowser und kann von uns nicht beeinflusst werden. Dieser Push-Dienst kann den Inhalt der Push-Nachricht nicht lesen, da diese verschl\u00FCsselt an Ihren Webbrowser \u00FCbertragen wird. Im Rahmen der Zustellung der Push-Nachricht kann es dazu kommen, dass Ihre IP-Adresse an diesen Push-Dienst \u00FCbertragen wird."),
                React.createElement("p", null, "Die Nutzung der Push-Nachrichten beruht auf Ihrer Einwilligung, die wir vorab anfragen. Rechtsgrundlage f\u00FCr die Verarbeitung ist mithin Artikel 6 Abs. 1 lit. a EU Datenschutzgrundverordnung. Sie k\u00F6nnen diese jederzeit mit Wirkung f\u00FCr die Zukunft widerrufen. Den Widerruf der Einwilligung k\u00F6nnen Sie in Ihrem Profil-Screen vornehmen. Durch den Widerruf wird die Rechtsm\u00E4\u00DFigkeit der bis zum Widerruf erfolgten Datenverarbeitung nicht ber\u00FChrt."),
                React.createElement("h3", null, "Aufbereitung von Nutzungsdaten f\u00FCr die Webstatistik"),
                React.createElement("p", null, "Wir m\u00F6chten unsere Applikation f\u00FCr Sie so ansprechend und komfortabel wie m\u00F6glich gestalten. Sehr hilfreich ist daf\u00FCr die Auswertung von statistischen Daten \u00FCber die Nutzung und die Erfassung verschiedener technischer Details des genutzten Dienstes unserer Besucherinnen und Besucher."),
                React.createElement("p", null, "Wir setzen aus diesem Grund das Webanalytik-Programm Matomo (ehemals PIWIK) ein, das mit JavaScript arbeitet und pseudonymisierte Nutzungsdaten automatisiert an uns \u00FCbertr\u00E4gt."),
                React.createElement("p", null,
                    "Die Speicherung und Auswertung erfolgt grunds\u00E4tzlich pseudonymisiert auf einem zentralen Server, der vom BIBB betrieben wird. Selbstverst\u00E4ndlich haben Sie die M\u00F6glichkeit der Datenerhebung zu widersprechen. Der Widerspruch ist sehr einfach und erfordert lediglich einen Klick. N\u00E4here Informationen zu den erhobenen Daten und zu den Widerspruchsm\u00F6glichkeiten finden Sie in unserer",
                    ' ',
                    React.createElement("button", { onClick: () => {
                            const element = document.getElementById('Datacollection-explanation');
                            if (element) {
                                element.scrollIntoView({
                                    behavior: 'smooth',
                                });
                            }
                        } }, "Erkl\u00E4rung zur Datenerhebung.")),
                React.createElement("h3", { id: "Datacollection-explanation" }, "Erkl\u00E4rung zur Datenerhebung"),
                React.createElement("h3", null, "M\u00F6glichkeiten des Widerspruches zur Erhebung von Nutzungsdaten f\u00FCr die Webstatistik"),
                React.createElement("p", null, "Wir setzen zur statistischen Datenerfassung das Webanalytik-Programm Matomo (ehemals PIWIK) ein."),
                React.createElement("p", null, "Sie haben folgende unabh\u00E4ngige M\u00F6glichkeiten, einer Datenerfassung durch den zentralen Matomo-Dienst des BIBB zu widersprechen:"),
                React.createElement("br", null),
                React.createElement("ul", { className: "numberedList" },
                    React.createElement("li", null, "Aktivieren Sie in Ihrem Browser die Einstellung Do-Not-Track bzw. Nicht folgen. Solange diese Einstellung aktiv ist, wird unser zentraler Matomo-Dienst keinerlei Daten von Ihnen speichern. Wichtig: Die Do-Not-Track-Anweisung gilt in der Regel nur f\u00FCr das eine Ger\u00E4t und den einen Browser, in dem Sie die Einstellung aktivieren. Sollten Sie mehrere Ger\u00E4te/Browser nutzen, dann m\u00FCssen Sie \"Do-Not-Track\" jeweils separat aktivieren."),
                    React.createElement("li", null,
                        "Nutzen Sie unsere Opt-Out-Funktion. Klicken sie auf den Schalter mit der Beschriftung \"Analyse App-Nutzung\" hier oder in den",
                        ' ',
                        React.createElement("a", { href: "/profile" }, "Einstellungen"),
                        ", um die Datenerfassung zu stoppen oder wieder zu aktivieren. Solange der Schalter deaktiviert ist, wird unser zentraler Matomo-Dienst keine Daten von Ihnen speichern.")),
                React.createElement("div", { className: "mx-2 w-96" },
                    React.createElement(ProfileLink, { title: "Analyse App-Nutzung", subTitle: 'Zustimmung zur Datenanalyse', toggle: {
                            onToggle: setConsent,
                            checked: consent === true,
                            dataTestId: 'Consent',
                        } })),
                React.createElement("h3", null, "Durch Matomo erfasste Daten"),
                React.createElement("p", null, "Folgende Daten werden - sofern Sie nicht widersprechen - durch den zentralen Matomo-Dienst des BIBB protokolliert und in die Nutzungsstatistiken einbezogen."),
                React.createElement("p", null, "Basisdaten:"),
                React.createElement("ul", { className: "italic" },
                    React.createElement("li", null, "IP-Adresse, durch Verk\u00FCrzen anonymisiert"),
                    React.createElement("li", null, "Zuvor besuchte URL (Referrer), sofern vom Browser \u00FCbermittelt"),
                    React.createElement("li", null, "Name und Version des Betriebssystems"),
                    React.createElement("li", null, "Name, Version und Spracheinstellung des Browsers")),
                React.createElement("p", null, "Zus\u00E4tzlich, sofern JavaScript aktiviert ist:"),
                React.createElement("ul", { className: "italic" },
                    React.createElement("li", null, "Besuchte URLs auf dieser Internetseite"),
                    React.createElement("li", null, "Zeitpunkte der Seitenaufrufe"),
                    React.createElement("li", null, "Art der HTML-Anfragen"),
                    React.createElement("li", null, "Bildschirmaufl\u00F6sung und Farbtiefe"),
                    React.createElement("li", null, "Vom Browser unterst\u00FCtzte Techniken und Formate (z. B. Java, PDF, WindowsMedia, QuickTime, Realplayer, Director, SilverLight, Google Gears)"),
                    React.createElement("li", null, "Ausgew\u00E4hlte Ereignisse (wie z. B. der Klick auf bestimmte Buttons)")),
                React.createElement("br", null),
                React.createElement("p", null, "Auf der Basis des von Ihnen verwendeten IP-Adressraums ermitteln und speichern wir mittels der frei verf\u00FCgbaren Datenbank \"GeoIP\", von welchem Staat aus die Seite aufgerufen wurde. Wir stellen nicht fest, ob Sie unsere Seite mit Ihrem eigentlichen Anschluss oder mit einem VPN oder mit einem Anonymisierungsdienst aufrufen. Ob die von uns erfasste IP-Adresse die eines VPNs oder eines Anonymisierungsdienstes ist, oder die von Ihnen f\u00FCr den Internetzugang verwendete, h\u00E4ngt von dem von Ihnen verwendeten VPN oder Anonymisierungsdienst ab. Das BIBB verwendet keine zus\u00E4tzlichen Techniken, Ihre tats\u00E4chliche IP-Adresse zu ermitteln oder zu verifizieren."),
                React.createElement("p", null, "Das BIBB wertet diese Daten allein f\u00FCr statistische Zwecke (Webstatistik) aus, um die BIBB-Internetangebote zu verbessern. Daten f\u00FCr die Webstatistik werden anonym (mit gek\u00FCrzter IP-Adresse) erhoben und k\u00F6nnen nicht einer bestimmten Person zugeordnet werden."),
                React.createElement("h3", null, "Durch die Serverstatistik erfasste Daten"),
                React.createElement("p", null, "Das Internetangebot kann frei von jeder personenbezogenen Protokollierung genutzt werden. Bei jedem Besuch einer Internetseite werden aber Daten, die Ihr Browser an unseren Cloud-Service \u00FCbermittelt, protokolliert:"),
                React.createElement("p", null),
                React.createElement("ul", { className: "italic" },
                    React.createElement("li", null, "Typ und Version Ihres Internet-Browsers"),
                    React.createElement("li", null, "verwendetes Betriebssystem"),
                    React.createElement("li", null, "die aufgerufene Seite"),
                    React.createElement("li", null, "die zuvor besuchte Seite (Referrer URL)"),
                    React.createElement("li", null, "Uhrzeit der Serveranfrage")),
                React.createElement("h3", null, "Durch die Applikation erfasste Daten"),
                React.createElement("p", null, "Aus technischen Gr\u00FCnden speichert unsere Applikation bei Ihrem Zugriff auf unsere Internetseiten personenbezogene Verbindungsdetails in Protokolldateien. Diese Daten werden jedoch lediglich zur unmittelbaren Bereitstellung unserer Dienste, zur St\u00F6rungsbeseitigung oder zur Abwehr eines Angriffs genutzt und nach kurzer Zeit automatisch gel\u00F6scht."),
                React.createElement("p", null, "Folgende Daten werden vor\u00FCbergehend gespeichert:"),
                React.createElement("ul", { className: "italic" },
                    React.createElement("li", null, "Ihre IP-Adresse"),
                    React.createElement("li", null, "Datum und Uhrzeit Ihres Zugriffs auf die Seite"),
                    React.createElement("li", null, "Adresse der aufgerufenen Seite"),
                    React.createElement("li", null, "Adresse der zuvor besuchten Internetseite"),
                    React.createElement("li", null, "Name und Version Ihres Browsers/Betriebssystems (sofern \u00FCbertragen)")),
                React.createElement("p", null, "Dar\u00FCber hinaus werden personenbezogene Daten nur dann gespeichert, wenn Sie diese selbst im Zuge der Registrierung (2. Datenverarbeitung durch personalisierte Services) angeben. Alternativ k\u00F6nnen Sie unseren Dienst \u00FCber den \u201E\u00F6ffentlichen Bereich\u201C nutzen, ohne weitere personenbezogene Daten anzugeben. Die \u00DCbertragung personengebundener Daten wird durch ein dem Stand der Technik entsprechendes Verfahren verschl\u00FCsselt (Secure Sockets Layer - SSL)."),
                React.createElement("h3", null, "Cookies"),
                React.createElement("p", null, "Nach \u00A725 TTDSG setzen wir nur technisch notwendige Cookies ein."),
                React.createElement("h2", null, "3. Verwendung von MIKA Game mit Registrierung/Anmeldung"),
                React.createElement("p", null, "Sofern Sie sich dazu entschieden haben, einen personalisierten Service in Anspruch zu nehmen (Registrierung \u201EMIKA-Game\u00AE\u201C), werden die folgenden erforderlichen und von Ihnen eingegebenen Daten gespeichert."),
                React.createElement("p", null, " Diese sind:"),
                React.createElement("ul", null,
                    React.createElement("li", null, "Ihr Benutzername \u2013 hier Ihr Realname mit Vor- und Nachnamen oder ein Nickname"),
                    React.createElement("li", null, "Ihre E-Mailadresse"),
                    React.createElement("li", null, "sowie fakultative Angaben zu Ihrer T\u00E4tigkeit, Ihrem Status und ein Profilbild. Das Profilbild kann mithilfe des Avatar-Editors erzeugt werden. Alternativ d\u00FCrfen Sie ein Foto von Ihnen verwenden. Stellen Sie kein Profilbild ein, wird ein Standard-Avatar angezeigt.")),
                React.createElement("p", null,
                    "Ihr Benutzerkonto und die dazugeh\u00F6rigen pers\u00F6nlichen Zugangsdaten erm\u00F6glichen Ihnen zus\u00E4tzlich den Zugriff auf alle im Leando-Portal kombinierten Services. Ihre Daten werden so lange nicht bei den weiteren personalisierten Services hinterlegt, bis Sie diese nicht durch einen Login-Vorgang in Anspruch nehmen. Details finden Sie in der",
                    ' ',
                    React.createElement("a", { href: "https://leando.de/artikel/datenschutz" }, "Datenschutzerkl\u00E4rung des Leando-Portals"),
                    "."),
                React.createElement("p", null, "MIKA Game soll den Nutzenden erm\u00F6glichen spielerisch ihr Wissen zu \u00FCberpr\u00FCfen und sich mit anderen zu messen. Um dies zu erm\u00F6glichen, verarbeiten und speichern wir auch Ihre Spieldaten."),
                React.createElement("p", null, "Diese Daten ben\u00F6tigen wir, damit MIKA Game funktioniert. Wir arbeiten dabei nach dem Grundsatz der Datensparsamkeit und erheben nur so viele personenbezogene Daten, wie unbedingt notwendig, Wenn Sie sich vom zentralen Service des \u201EMIKA-Game\u00AE\u201C abmelden, k\u00F6nnen die angebundenen personalisierten Dienste (z. B. Ihr Highscore) nur durch eine erneute Anmeldung genutzt werden. Alternativ ist die Nutzung des Internetangebotes ohne personalisierten Service ohne Registrierung in einem \u00F6ffentlichen Bereich m\u00F6glich."),
                React.createElement("h3", null, "Sichtbarkeit Ihres Profils und Ihrer Spieldaten f\u00FCr andere Nutzende"),
                React.createElement("p", null, "Zus\u00E4tzlich zu den unter (2) beschriebenen Punkten sind Ihr Benutzername, Ihr Profil und Ihre Spieldaten f\u00FCr folgende Nutzende unter folgenden Umst\u00E4nden sichtbar:"),
                React.createElement("ul", null,
                    React.createElement("li", null, "Auf der \u201EHome\u201C-Seite werden die Top-5 Spielenden nach Gesamtpunktzahl absteigend angezeigt. Zus\u00E4tzlich werden die zwei Spielenden angezeigt, deren Gesamtpunktzahl direkt \u00FCber und unter der des jeweiligen Nutzenden steht."),
                    React.createElement("li", null, "Sie haben die M\u00F6glichkeit eigene Fragen zu erstellen. Wenn Sie diese M\u00F6glichkeit nutzen, wird bei diesen Fragen Ihr Benutzername eingeblendet."),
                    React.createElement("li", null,
                        "Sind Sie Teil eines Teams,",
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                "k\u00F6nnen andere Mitglieder des Teams sehen,",
                                React.createElement("ul", null,
                                    React.createElement("li", null, "dass Sie Teil des Teams sind und"),
                                    React.createElement("li", null, "welche Punktzahl Sie bei Quizzen, bei denen das jeweilige Team beteiligt war, erreicht haben. Alle Spielenden werden nach ihrem Punktestand absteigend sortiert angezeigt."))),
                            React.createElement("li", null,
                                "kann die Team-Administration zus\u00E4tzlich zu den eben genannten Punkten folgende Informationen sehen:",
                                React.createElement("ul", null,
                                    React.createElement("li", null, "Anzahl der Fragen, die Sie (absolut und als Prozentsatz aller von Ihnen beantworteten Fragen) \u00FCber die gesamte Zeit hinweg und in der letzten Woche insgesamt und f\u00FCr jede der sechs MIKA-Themen/Kategorien richtig beantwortet haben."),
                                    React.createElement("li", null, "Anzahl der Fragen, die das Team (absolut und als Prozentsatz aller von Ihnen beantworteten Fragen) \u00FCber die gesamte Zeit hinweg und in der letzten Woche insgesamt und f\u00FCr jede der sechs MIKA-Themen/Kategorien richtig beantwortet hat."),
                                    React.createElement("li", null, "Die Team-Administration hat die M\u00F6glichkeit eine csv-Datei herunterzuladen. In dieser Datei sind folgende Informationen enthalten: Nickname (\u201EMember Name\u201C), Text der Frage (\u201EQuestion Text\u201C), Kategorie (\u201ECategory Text\u201C), Antwort (\u201EAnswer Text\u201C), eine Informationen dar\u00FCber, wie die Frage beantwortet wurde (\u201ECorrect\u201C), Datum, an dem das Spiel stattgefunden hat (\u201EChallenge Date\u201C)."))),
                            React.createElement("li", null, "Wenn Sie ein Team verlassen, sind Ihre Spieldaten nicht mehr sichtbar und k\u00F6nnen auch nicht mehr \u00FCber die csv-Datei heruntergeladen werden. Ihre bis zum Verlassen des Teams erspielten Punkte flie\u00DFen jedoch weiter in den Teamdurchschnitt ein.")))),
                React.createElement("h3", null, "L\u00F6schung Ihres Accounts (Art. 16 DSGVO)"),
                React.createElement("p", null,
                    "Wenn Sie sich vom personalisierten Service von MIKA sowie allen im Leando-Portal kombinierten Services dauerhaft abmelden m\u00F6chten, kontaktieren Sie bitte die MIKA-Redaktion (",
                    React.createElement("a", { href: "mailto:mika@bibb.de" }, "mika@bibb.de"),
                    "). Dieser Vorgang ist unwiderruflich und kann nicht r\u00FCckg\u00E4ngig gemacht werden."),
                React.createElement("h2", null, "4. Speicherung und Sicherung Ihrer Daten"),
                React.createElement("h3", null, "Hosting und technischer Support "),
                React.createElement("p", null, "Die Verwaltung und Speicherung Ihrer Daten erfolgt im Rahmen der Auftragsverarbeitung auf den Systemen von bringe Informationstechnik GmbH in Karlsruhe Die Firmen sprylab technologies GmbH in Berlin (Frontend) sowie die Kr\u00F6pelin Projekt GmbH, ebenfalls Berlin (Backend), leisten den technischen Support. Mit den Auftragsverarbeitern bestehen Vertr\u00E4ge zur Auftragsverarbeitung."),
                React.createElement("h3", null, "Sicherheit"),
                React.createElement("p", null, "Wir setzen technische und organisatorische Sicherheitsma\u00DFnahmen ein, um Ihre Daten gegen zuf\u00E4llige oder vors\u00E4tzliche Manipulationen, Verlust, Zerst\u00F6rung oder gegen den Zugriff unberechtigter Personen zu sch\u00FCtzen. Unsere Sicherheitsma\u00DFnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert."),
                React.createElement("h3", null, "Daten\u00FCbermittlung"),
                React.createElement("p", null, "Ihre personenbezogenen Daten werden vom MIKA-Team bearbeitet oder den Mitarbeitenden unserer vertraglichen verbundenen Dienstleister. Es empfangen nur die Besch\u00E4ftigten personenbezogene Daten, die mit der jeweiligen Vorgangsbearbeitung zust\u00E4ndigkeitshalber befasst sind. Ihre personenbezogenen Daten werden dar\u00FCber hinaus nur in den gesetzlich erforderlichen F\u00E4llen bzw. zur Strafverfolgung aufgrund von Angriffen auf unsere Netzinfrastruktur an staatliche Einrichtungen und Beh\u00F6rden \u00FCbermittelt. Eine Weitergabe zu anderen Zwecken an Dritte findet ausdr\u00FCcklich nicht statt."),
                React.createElement("h2", null, "5. Ihre Rechte"),
                React.createElement("p", null, "Sie haben gegen\u00FCber dem Verantwortlichen folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:"),
                React.createElement("ul", { className: "italic" },
                    React.createElement("li", null, "Recht auf Auskunft, Art. 15 DSGVO"),
                    React.createElement("li", null, "Recht auf Berichtigung, Art. 16 DSGVO"),
                    React.createElement("li", null, "Recht auf Berichtigung und L\u00F6schung, Art. 17 DSGVO"),
                    React.createElement("li", null, "Recht auf Einschr\u00E4nkung der Verarbeitung, Art. 18 DSGVO"),
                    React.createElement("li", null, "Recht auf Widerspruch gegen die Erhebung, Verarbeitung und/oder Nutzung, Art. 21 DSGVO"),
                    React.createElement("li", null, "Recht auf Daten\u00FCbertragbarkeit, Art. 20 DSGVO")),
                React.createElement("p", null, "Soweit die Verarbeitung der personenbezogenen Daten auf Grundlage Ihrer Einwilligung (Artikel 6 Abs. 1 lit a DSGVO) erfolgt, k\u00F6nnen Sie diese jederzeit f\u00FCr den entsprechenden Zweck widerrufen. Die Rechtm\u00E4\u00DFigkeit der Verarbeitung aufgrund Ihrer get\u00E4tigten Einwilligung bleibt bis zum Eingang Ihres Widerrufs unber\u00FChrt."),
                React.createElement("p", null,
                    "Die vorgenannten Rechte k\u00F6nnen Sie bei",
                    ' ',
                    React.createElement("a", { href: "mailto:mika@bibb.de" }, "mika@bibb.de"),
                    " geltend machen."),
                React.createElement("p", null,
                    "Ihnen steht zudem ein ",
                    React.createElement("b", null, "Beschwerderecht"),
                    " bei der datenschutzrechtlichen Aufsichtsbeh\u00F6rde, (Bundesbeauftragte f\u00FCr den Datenschutz und die Informationsfreiheit, Graurheindorfer Str. 153 in 53117 Bonn) zu."),
                React.createElement("p", null,
                    "Sie k\u00F6nnen sich mit Fragen und Beschwerden auch an die",
                    ' ',
                    React.createElement("a", { href: "https://www2.bibb.de/bibbtools/de/ssl/kontakt.php?maid=4061" }, "Datenschutzbeauftragte des BIBB"),
                    ' ',
                    "wenden."),
                React.createElement("h3", null, "Datenschutzbeauftragte"),
                React.createElement("p", null,
                    "F\u00FCr weitere Fragen und Ausk\u00FCnfte rund um das Thema Datenschutz stehen Ihnen die Datenschutzbeauftragten des Bundesinstituts f\u00FCr Berufsbildung zur Verf\u00FCgung.",
                    React.createElement("br", null),
                    " Bitte wenden Sie sich an die Datenschutzbeauftragten des BIBB:"),
                React.createElement("p", null,
                    "Tel. ",
                    React.createElement("a", { href: "tel:+492281072237" }, "0228 107 \u2013 2237")),
                React.createElement("p", null,
                    "E-Mail:",
                    ' ',
                    React.createElement("a", { href: "mailto:datenschutz@bibb.de" }, "datenschutz@bibb.de")),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement("br", null)))));
}
